import React, { useState, useEffect, useRef } from "react";
import WebLogo from "../assets/images/unpwnableThings-Logo.png";
import Pwnsters from "../assets/images/pwnsters.png";
// import Logo from "../assets/images/logo.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import BlogImage from "../assets/images/blogImage-1.png";

import "../styles/Navigate.css";

const Navigate = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState("Home");
  // const [showUserMenu, setShowUserMenu] = useState(false);
  // mobile menu
  const [showMenu, setShowMenu] = useState(false);
  const btRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleClick = (e) => {
      if (
        btRef.current &&
        !btRef.current.contains(e.target) &&
        menuRef.current &&
        !menuRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleClick);
    };
  }, []);

  const handleUserClick = () => {
    setShowMenu(!showMenu);
  };

  const handleLinkClick = (link, e) => {
    e.preventDefault();
    setActiveLink(link);
    const targetElementCollaborative = document.getElementById(link);
    if (link) {
      const offset = -160;
      const targetPosition = targetElementCollaborative.offsetTop + offset;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleMobileLinkClick = (link, e) => {
    e.preventDefault();
    const targetElementCollaborative = document.getElementById(link);
    if (link) {
      const offset = -120;
      const targetPosition = targetElementCollaborative.offsetTop + offset;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        <nav
          className={`lg:px-[7rem] sm:px-16 px-8 sm:justify-center fixed top-0 w-full z-10 transition duration-400 ease-in-out ${
            isScrolled
              ? "lg:dektop-style-menu mobile-style-menu blur-background"
              : "bg-transparent "
          } ${isScrolled && showMenu ? "expand-the-menu" : ""}`}
        >
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-8">
            <a
              href="#Home"
              className="flex items-center"
              onClick={(e) => handleMobileLinkClick("Home", e)}
            >
              <img src={WebLogo} className="h-10 mr-3" alt="Pwnsters Logo" />
            </a>
            <div className="flex items-center lg:order-2">
              <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto lg:hidden">
                <button
                  ref={btRef}
                  type="button"
                  onClick={handleUserClick}
                  className="inline-flex items-center justify-center p-2 w-10 h-10 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-[#ff6b6bb8] dark:hover:bg-[#89060d] dark:focus:ring-[#89060d]"
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 17 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1h15M1 7h15M1 13h15"
                    />
                  </svg>
                </button>
                <div ref={menuRef} className="w-full" id="navbar-hamburger">
                  <div
                    className={`absolute top-[90px] right-0 w-full ${
                      showMenu ? "block" : "hidden"
                    } z-20`}
                  >
                    <ul
                      className={`flex flex-col font-medium mt-4 rounded-lg h-full ${
                        isScrolled ? " divide-y" : "mobile-style-menu"
                      } `}
                    >
                      <li>
                        <a
                          href="#Home"
                          className="block py-2 pl-3 pr-4 text-black rounded "
                          aria-current="page"
                          onClick={(e) => handleMobileLinkClick("Home", e)}
                        >
                          Home
                        </a>
                      </li>
                      <li>
                        <a
                          href="#Unpwnable Things"
                          className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 dark:text-black dark:hover:bg-gray-700 dark:hover:text-black"
                          onClick={(e) =>
                            handleMobileLinkClick("Unpwnable Things", e)
                          }
                        >
                          Unpwnable Things
                        </a>
                      </li>
                      <li>
                        <a
                          href="#Advantages"
                          className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 dark:text-black dark:hover:bg-gray-700 dark:hover:text-black"
                          onClick={(e) =>
                            handleMobileLinkClick("Advantages", e)
                          }
                        >
                          Advantages
                        </a>
                      </li>
                      <li>
                        <a
                          href="#IOT"
                          className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 dark:text-black dark:hover:bg-gray-700 dark:hover:text-black"
                          onClick={(e) => handleMobileLinkClick("IOT", e)}
                        >
                          IOT
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://pwnsters.unpwnable.io"
                          className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 dark:text-black dark:hover:bg-gray-700 dark:hover:text-black"
                        >
                          Pwnsters
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://blog.unpwnable.io"
                          className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 dark:text-black dark:hover:bg-gray-700 dark:hover:text-black"
                        >
                          Blog
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* user-dropdown menu
                        <button type="button" ref={btRef} className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" onClick={handleUserClick}>
                            <span className="sr-only">Open user menu</span>
                            <img className="w-12 h-12 rounded-full" src='https://i.pravatar.cc/300' alt="Photo" />
                        </button>

                        <div className='relattve' ref={menuRef}>
                            <div className={`absolute top-20 right-16 ${showUserMenu ? 'block' : 'hidden'} z-50 my-4 text-base list-none divide-y divide-gray-800 rounded-lg shadow bg-white dark:divide-gray-600`}>                                <div className="px-4 py-3">
                                <span className="block text-sm text-gray-900">Bonnie Green</span>
                                <span className="block text-sm  text-gray-600 truncate">name@flowbite.com</span>
                            </div>
                                <ul className="py-2" aria-labelledby="user-menu-button">
                                    <li>
                                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-900 dark:hover:text-white">Dashboard</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-900 dark:hover:text-white">Settings</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-900 dark:hover:text-white">Earnings</a>
                                    </li>
                                    <li>
                                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-900 dark:hover:text-white">Sign out</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button data-collapse-toggle="navbar-user" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-user" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button> */}
            </div>

            <div
              className="items-center justify-between hidden w-full lg:flex lg:w-auto lg:order-1"
              id="navbar-user"
            >
              <ul className="flex flex-col font-medium p-4 sm:p-0 mt-4 border  rounded-lg  sm:flex-row sm:space-x-8 sm:mt-0 sm:border-0 ">
                <li>
                  <a
                    href="#Home"
                    className={`${
                      isScrolled
                        ? "text-black sm:p-0  rounded-full"
                        : "text-white sm:text-[#0E131F] sm:p-0 sm:dark:text-white "
                    } block py-2 pl-3 pr-4 transform translate-y-0 hover:translate-y-1 transition-transform  ${
                      activeLink === "Home" ? "active text-white" : ""
                    } rounded-full`}
                    onClick={(e) => handleLinkClick("Home", e)}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#Unpwnable Things"
                    className={`${
                      isScrolled
                        ? "text-black hover:bg-gray-100 sm:hover:bg-transparent sm:hover:text-[#0E131F] sm:p-0  sm:dark:hover:text-[#0E131F] dark:hover:bg-gray-700  sm:dark:hover:bg-transparent dark:border-gray-700"
                        : "hover:bg-gray-100 sm:hover:bg-transparent sm:hover:text-[#0E131F] sm:p-0 dark:text-white sm:dark:hover:text-[#0E131F] dark:hover:bg-gray-700 dark:hover:text-white sm:dark:hover:bg-transparent dark:border-gray-700"
                    } block py-2 pl-3 pr-4 rounded-full transform translate-y-0 hover:translate-y-1 transition-transform  ${
                      activeLink === "Unpwnable Things"
                        ? "active text-white"
                        : ""
                    }`}
                    onClick={(e) => handleLinkClick("Unpwnable Things", e)}
                  >
                    Unpwnable Things
                  </a>
                </li>
                <li>
                  <a
                    href="#Advantages"
                    className={`${
                      isScrolled
                        ? "text-black hover:bg-gray-100 sm:hover:bg-transparent sm:hover:text-[#0E131F] sm:p-0  sm:dark:hover:text-[#0E131F] dark:hover:bg-gray-700  sm:dark:hover:bg-transparent dark:border-gray-700"
                        : "hover:bg-gray-100 sm:hover:bg-transparent sm:hover:text-[#0E131F] sm:p-0 dark:text-white sm:dark:hover:text-[#0E131F] dark:hover:bg-gray-700 dark:hover:text-white sm:dark:hover:bg-transparent dark:border-gray-700"
                    } block py-2 pl-3 pr-4 rounded-full transform translate-y-0 hover:translate-y-1 transition-transform  ${
                      activeLink === "Advantages" ? "active text-white" : ""
                    }`}
                    onClick={(e) => handleLinkClick("Advantages", e)}
                  >
                    Advantages
                  </a>
                </li>

                <li>
                  <a
                    href="#IOT"
                    className={`${
                      isScrolled
                        ? "text-black hover:bg-gray-100 sm:hover:bg-transparent sm:hover:text-[#0E131F] sm:p-0  sm:dark:hover:text-[#0E131F] dark:hover:bg-gray-700  sm:dark:hover:bg-transparent dark:border-gray-700 transform translate-y-0 hover:translate-y-1 transition-transform"
                        : "hover:bg-gray-100 sm:hover:bg-transparent sm:hover:text-[#0E131F] sm:p-0 dark:text-white sm:dark:hover:text-[#0E131F] dark:hover:bg-gray-700 dark:hover:text-white sm:dark:hover:bg-transparent dark:border-gray-700"
                    } block py-2 pl-3 pr-4 rounded-full transform translate-y-0 hover:translate-y-1 transition-transform  ${
                      activeLink === "IOT" ? "active text-white" : ""
                    }`}
                    onClick={(e) => handleLinkClick("IOT", e)}
                  >
                    IOT
                  </a>
                </li>
              </ul>
            </div>
            <div className="order-3 lg:flex hidden">
              <a
                href="https://pwnsters.unpwnable.io"
                className="flex items-center text-black font-bold mr-[20px]"
              >
                <img src={Pwnsters} className="h-10" alt="Pwnsters Logo" />
                Pwnsters
              </a>
              <a
                href="https://blog.unpwnable.io"
                className="flex items-center text-black font-bold"
              >
                <img
                  src={BlogImage}
                  className="h-10 mr-[-20px]"
                  alt="Blog Logo"
                />
                Blog
              </a>
            </div>
          </div>
        </nav>
      </motion.div>
    </>
  );
};

export default Navigate;
