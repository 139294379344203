import React, { useState } from 'react'

import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import '../styles/IOT.css'

const IOT = () => {
    const [ref, inView] = useInView({
        triggerOnce: true, // Trigger the animation only once
    })

    const [iotRes, setIotRes] = useState([
        'Security researchers are deprived of equipment accessibility',
        'The IoT design usually leads to eliminating unnecessary codes, including security features',
        'There are no effective controls for them',
    ])

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.4,
                staggerChildren: 0.4,
            },
        },
    }

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
        },
    }

    return (
        <div
            id="IOT"
            className="header-font my-32 lg:px-[7rem] md:px-[3rem] sm:px-[1rem] mx-auto flex flex-col justify-center items-center relative "
        >
            <h2 className="sm:text-3xl text-2xl infinite-gradient-text sm:mx-4 mx-10 text-center p-3">
                OT and IIoT
            </h2>
            <p className="my-10 text-lg lg:px-56 md:px-24 sm:px-16 px-12 text-center">
                <span className="font-bold italic">
                    In NIST 800-53 and NIST 800-115’s
                </span>{' '}
                style organizational risk assessment and penetration test which
                mainly focused on known vulnerabilities, embedded devices, and
                IoT will not be properly reviewed. For the sake of the primitive
                vulnerabilities they have, hackers can take advantage of this
                gap to gain initial access or do a lateral movement.
            </p>
            <motion.ul
                className="w-full lg:h-auto md:h-[25vh] grid overflow-hidden md:my-20 my-5 list-none md:grid-cols-3 md:grid-rows-1 grid-cols-1 grid-rows-3 gap-15 p-15 bg-opacity-20 rounded-50 md:px-10 px-10"
                variants={container}
                ref={ref}
                animate={inView ? 'visible' : 'hidden'}
                initial="hidden"
            >
                {iotRes.map((IOT) => (
                    <motion.li
                        key={IOT}
                        className="bg-[#2b62aa] rounded-3xl p-16 md:w-[22vw] lg:h-[25vh] xl:h-[25vh] w-[70vw] h-auto md:h-auto mx-auto text-center text-white text-lg items-center flex my-5 md:my-0"
                        variants={item}
                    >
                        {IOT}
                    </motion.li>
                ))}
            </motion.ul>
            <p className="my-10 text-lg lg:px-56 md:px-24 sm:px-16 px-12 text-center">
                The Internet of Things (IoT) is rapidly evolving with the
                potential to revolutionize how we interact with the world around
                us. The same thing is going to happen in Operation Technology or
                (OT). However, security in OT is not just a major concern but
                extreme worry. We believe that If we want to make the world more
                secure we need to equip sysadmins with the knowledge and tools
                of a security researcher with either operating system audit
                abilities or vulnerability research abilities; this is what
                unpwnable THINGS is supposed to be!
            </p>
        </div>
    )
}

export default IOT
