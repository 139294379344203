import React, { useState } from "react";
import ScrollCarousel from "scroll-carousel-react";

import "../styles/UnpwnableThings.css";

const Advantages = () => {
  const [advanteges, setAdvanteges] = useState([
    "Zero-Day Hunter Engine",
    "OS Audit and admin’s security suggester sub-system",
    "SSE integration for mitigating risks and inline prevention",
    "Privileged Session Management and a fancy Terminal",
    "Using HSM for key/pass management",
    "Pwnsters threat intelligence and intelligent threat detection",
  ]);

  return (
    <div
      id="Advantages"
      className="header-font my-24 lg:px-[1rem] md:px-[3rem] sm:px-[1rem] mx-auto flex flex-col justify-center items-center relative"
    >
      <h2 className="sm:text-3xl text-2xl infinite-gradient-text p-3 text-center">
        a Smart and Granular Sysadmin Assistant
      </h2>
      <ScrollCarousel
        autoplay
        autoplaySpeed={7}
        speed={5}
        direction={"ltr"}
        smartSpeed={true}
        className="my-20 text-lg xl:px-[25rem] lg:px-56 md:px-24 sm:px-16 text-center"
      >
        {advanteges.map((item) => (
          <div
            key={item}
            className="bg-[#173185] text-white border-1 border-[#eee] rounded-2xl sm:h-40 sm:w-52 h-36 w-44 text-lg flex justify-center items-center line-lg"
          >
            {item}
          </div>
        ))}
      </ScrollCarousel>
    </div>
  );
};

export default Advantages;
