import React from "react";
import HeaderBackground from "../assets/images/header-background.png";
// import Programmer from '../assets/images/programmer.png';
import Waves from "../assets/images/waves.png";
import UnpwnableThings3D from "../assets/images/Unpwnable-Things-3D.png";
import "../styles/Header.css";
import Navigate from "./Navigate";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";

const Header = () => {
  const animationVariants = {
    hidden: {
      opacity: 0,
      x: -10,
      y: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.2,
      },
    },
  };

  return (
    <div
      className="bg-cover bg-center h-[100vh] lg:h-[120vh] relative z-10"
      style={{
        backgroundImage: `url(${HeaderBackground})`,
      }}
      id="Home"
    >
      <div>
        <Navigate />
      </div>
      <div className="absolute mt-32 xl:ml-64 pr-16 w-full block lg:w-4/6 lg:px-10 pt-10 md:px-8 px-6">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={animationVariants}
        >
          <h1 className="xl:text-6xl md:text-4xl text-white text-3xl main-header md:w-2/3 lg:w-3/4">
            The product acts how hackers do in the wild, in an
          </h1>
          <TypeAnimation
            preRenderFirstString={true}
            sequence={[2500, "automated", 2500, "intelligent", 2500]}
            speed={30}
            repeat={Infinity}
            wrapper="h1"
            className="xl:text-6xl md:text-4xl text-[#72a4fb] text-3xl main-header"
          />
          <span className="xl:text-6xl md:text-4xl text-white text-3xl main-header">
            way
          </span>
          <p className="text-[#dbdbdb] sm:mt-10 mt-8 md:w-3/4 sm:w-2/5 md:text-xl w-full text-sm lg:w-2/3 header-font lg:text-sm xl:text-lg">
            unpwnable THINGS™ not only can be used by your information security department but also it is designed for network administrators to proactively address zero-day vulnerabilities while they are managing their assets!
          </p>
        </motion.div>
      </div>
      <motion.div
        initial={{ x: "100%", y: "100vh" }}
        animate={{ x: 0, y: 0 }}
        transition={{ duration: 1 }}
        className="z-10"
      >
        <img
          src={UnpwnableThings3D}
          alt="UnpwnableThings3D"
          className="absolute lg:block lg:top-[35vh] lg:right-[10vh] xl:top-[30vh] xl:right-[1vh] xl:w-[700px] lg:w-[500px] md:hidden right-[0vh] top-[60vh] hidden"
        />
      </motion.div>
      <img src={Waves} alt="" className="absolute bottom-[-3vh] z-0`" />

      {/* <img src={Programmer} alt="" className='absolute block lg:right-[20vh] md:right-[14vh] md:bottom-[35vh] lg:w-80 lg:h-80 md:w-64 md:h-64 sm:h-52 sm:w-52 sm:right-[30vh] sm:bottom-[12vh] bottom-[12vh] right-[30vh] h-52 w-52 object-cover animate-float infinite' /> */}
    </div>
  );
};

export default Header;
