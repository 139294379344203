import React from 'react'
import Header from './components/Header';

import './styles/App.css';
import AboutTheApp from './components/AboutTheApp';
import IOT from './components/IOT';
import Advantages from './components/Advantages';
import Footer from './components/Footer';

const App = () => {
  return (
    <div className='main-body'>

      <Header />
      <AboutTheApp />
      {/*<MotionGraphy videoId={'-z-HP-g-eJI'} />*/}
      {/* <UnpwnableThings /> */}
      <Advantages />
      <IOT />
      <Footer />

    </div>
  )
}

export default App