import React from "react";

import Collabrium from "../assets/images/Unpwnable.png";
import "../styles/AboutTheApp.css";

const AboutTheApp = () => {
  return (
    <div
      className="my-32 lg:px-[7rem] md:px-[3rem] sm:px-[1rem] mx-auto flex flex-col justify-center items-center relative header-font bg-contain bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${Collabrium})` }}
      id="Unpwnable Things"
    >
      <div className="gradiant-01 rounded-full sm:w-[500px] sm:h-[500px] w-[200px] h-[300px]"></div>

      <h2 className="text-2xl font-bold gradient-text text-center">
        unpwnable THINGS™
      </h2>
      <h3 className="mt-8 sm:text-3xl text-center text-2xl bold infinite-gradient-text md:p-3 header-font">
        What does unpwnable THINGS™ do in your network?
      </h3>
      <div className="gradiant-02 md:right-[15%] sm:right-[30%] hidden sm:block"></div>
      <div className="gradiant-03 rounded-full sm:w-[300px] sm:h-[500px] w-[200px] h-[300px]"></div>

      <p className="my-10 text-lg lg:px-56 md:px-24 sm:px-16 px-12 text-center">
        <span className="font-bold">unpwnable THINGS™ </span> is your assistant
        and a SASE-aware privileged session management for hardening your{" "}
        <span className="font-bold italic">IT and OT nodes </span>by auditing
        their OS and hunting zero-day vulnerabilities of your less secure
        IT/OT’s equipment that might be used as a hackers’ initial access
        infrastructure or being targeted to disrupt your OT. <br />
        In other words, it acts as an
        <span className="font-bold italic">
          {" "}
          SSH/Remote Desktop gateway
        </span>{" "}
        for generating logs and preventing the execution of unwanted
        high-privileged OS commands by force or propagating useful security
        information directly to sysadmins!
      </p>
    </div>
  );
};

export default AboutTheApp;
