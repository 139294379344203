import React from "react";

// import Logo from '../assets/images/logo.png';
import Wave from "../assets/images/waves.png";
import Contact from "../assets/images/contact.png";
import Telegram from "../assets/images/Telegram.png";

import "../styles/UnpwnableThings.css";

const Footer = () => {
  return (
    <div className="mt-10 w-full bg-[#2664c0] relative overflow-hidden header-font">
      {/* <div class="flex flex-1 h-full w-full items-center">
                <div className='flex flex-col items-center justify-between'>
                    <img src={Logo} alt="Collabrium" className='h-24 text-center justify-center px-28' />
                    <h4 className='bold mt-10 text-white text-2xl'>Collabrium</h4>
                </div>
                <div
                    class="h-[100%] min-h-[1em] w-px self-stretch bg-gradient-to-tr from-transparent via-white to-transparent opacity-60 dark:opacity-60"></div>
                <div className=''>
                    <h4 className='bold mt-10 text-white text-2xl '>Contact US</h4>
                </div>
            </div> */}
      <div className="md:px-32 sm:px-12 px-12">
        <img
          src={Wave}
          alt=""
          className="absolute md:top-[-14px] top-0 right-0 rotate-180"
        />

        <div className="sm:mt-36 mt-24 pt-20 pt-10">
          <h4 className="bold text-xl text-[#fff] flex items-center">
            <img src={Contact} alt="" className="w-8 mr-4" />
            Contact us
          </h4>

          <div className="relative sm:flex mt-2 sm:text-xs md:text-lg">
            <p className="text-sm text-[#fff] py-4 pl-5">
              {" "}
              Office 1116 The Metropolis Tower, Business Bay, Dubai, UAE
            </p>
            <p className="mr-2 items-center flex  ml-5 text-[#fff] text-sm">
              {" "}
              +971 567 877377
            </p>
          </div>
          <p className="mr-2  flex  ml-5 text-[#fff] text-sm mb-8">
            {" "}
            mehrun@unpwnable.io
          </p>
        </div>

        <h4 className="bold text-xl text-[#fff] mt-[1rem] flex items-center mb-4">
          <img src={Telegram} alt="" className="w-8 mr-4" />
          Talk to us on Telegram{" "}
        </h4>

        <div className="relative mb-8 sm:flex justify-between sm:text-xs md:text-lg">
          <p className="text-sm text-[#fff] py-2 pl-5">
            Please let us know if you have further questions.
          </p>
          <button className="bg-[#0400ff91] rounded-full sm:h-10 sm:p-4 sm:mx-0 sm:ml-16 items-center flex md:float-right mx-auto my-4 text-[#fff] h-10 p-3 sm:text-sm text-xs md:text:sm hover:translate-y-1">
            <a href="https://t.me/theunpwnable" target="_blank">
              Send Message 
            </a>
          </button>
        </div>
      </div>
      <div className="copyright-symbol text-black opacity-80 justify-center flex text-xs py-4 sm:text-[10px] text-white w-full">
        &copy;2024 UnpwnableThings, All rights reserved
      </div>
    </div>
  );
};

export default Footer;
